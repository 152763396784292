export function prefix(location, ...prefixes) {
  return prefixes.some(
    (prefix) => location.href.indexOf(`${location.origin}/${prefix}`) !== -1
  );
}

export function authorization(location){
  return prefix(location, "auth");
}

export function breadcrumb() {
  // The breadcrumb is always active
  return true;
}

export function navbar() {
  // The nav is always active
  return true;
}

export function sidebar(){
  return true;
}

export function dashboard(location) {
  return prefix(location, "dashboard");
}

export function overview(location) {
  return prefix(location, "overview");
}

export function cluster_analysis(location) {
  return prefix(location, "clustering-analysis");
}

export function geo_listening(location) {
  return prefix(location, "geo-listening");
}
