import { registerApplication, start } from "single-spa";
import * as isActive from "./activity-functions";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

registerApplication(
  "@isc/authorization",
  () => System.import("@isc/authorization"),
  isActive.authorization,
  { domElement: document.getElementById("authorization") }
);

registerApplication(
  "@isc/breadcrumb",
  () => System.import("@isc/breadcrumb"),
  isActive.breadcrumb,
  { domElement: document.getElementById("breadcrumb") }
);

registerApplication(
  "@isc/sidebar",
  () => System.import("@isc/sidebar"),
  isActive.sidebar,
  { domElement: document.getElementById("sidebar") }
);

registerApplication(
  "@isc/navbar",
  () => System.import("@isc/navbar"),
  isActive.navbar,
  { domElement: document.getElementById("navbar") }
);

registerApplication(
  "@isc/dashboard",
  () => System.import("@isc/dashboard"),
  isActive.dashboard,
  { domElement: document.getElementById("dashboard") }
);

registerApplication(
  "@isc/overview",
  () => System.import("@isc/overview"),
  isActive.overview,
  { domElement: document.getElementById("overview") }
);

registerApplication(
  "@isc/clustering-analysis",
  () => System.import("@isc/clustering-analysis"),
  isActive.cluster_analysis,
  { domElement: document.getElementById("clustering-analysis") }
);

registerApplication(
  "@isc/geo-listening",
  () => System.import("@isc/geo-listening"),
  isActive.geo_listening,
  { domElement: document.getElementById("geo-listening") }
);

start();
